import { MessageDisplay } from 'elements/MessageDisplay/MessageDisplay';
import Text from 'elements/Text';
import { FC } from 'react';
import * as Icons from 'react-feather';

export const Banner: FC<{
  variation?: 'default' | 'warning' | 'success' | 'error';
  size?: 'default' | 'mini' | 'small' | 'medium';
  className?: string;
  hideImage?: boolean;
  icon?: {
    icon?: keyof typeof Icons;
    props?: Icons.IconProps;
  };
}> = ({
  children,
  variation = 'default',
  icon,
  className,
  size = 'mini',
  hideImage = true,
}) => {
  return (
    <MessageDisplay
      icon={icon}
      size={size}
      className={className ?? ''}
      variation={variation}
      hideImage={hideImage}
    >
      <Text.Paragraph className="fs12 font-semibold">{children}</Text.Paragraph>
    </MessageDisplay>
  );
};
