/* eslint-disable sonarjs/no-duplicate-string */
import Button from 'elements/Button';
import Text from 'elements/Text';
import { FC, ReactNode } from 'react';
import * as Icons from 'react-feather';

export type MessageDisplayInputType = Record<
  string,
  { heading: string; subheading: string; variation: string; show: boolean }
>;

const variations: {
  [key: string]: {
    classNames: string[];
    image: string;
  };
} = {
  default: {
    classNames: ['bg-visto-light-ultra', 'border-visto-gray-blue'],
    image: '/box_hearts_face.png',
  },
  success: {
    classNames: ['bg-green-50', 'border', 'border-green-500'],
    image: '/browser-ui.png',
  },
  warning: {
    classNames: ['bg-gold', 'border', 'border-yellow-500'],
    image: '/browser-ui.png',
  },
  error: {
    classNames: ['bg-red-100', 'border', 'border-red-400'],
    image: '/browser-ui.png',
  },
  blue: {
    classNames: [
      'bg-gradient-to-r',
      'from-[#2897f2]',
      'to-blue-600',
      'rounded-md',
      'border-none',
      'text-white',
    ],
    image: '/browser-ui.png',
  },
};

const images: { [key: string]: string } = {
  magnify: '/magnify-eye-glass.png',
  browser: '/browser-ui.png',
  lightning: '/lighting-bolt.png',
};

const sizes: { [key: string]: { container: string; heading: string } } = {
  default: {
    container: 'p-4',
    heading: 'fs18',
  },
  mini: {
    container: 'py-1.5 px-3',
    heading: 'fs12',
  },
  small: {
    container: 'p-3',
    heading: 'fs14',
  },
  medium: {
    container: 'p-4',
    heading: 'fs14',
  },
};

interface MessageDisplayPropTypes {
  heading?: string;
  subheading?: string;
  show?: boolean;
  variation?: string;
  className?: string;
  openModal?: (isModalOpen: boolean) => void;
  cta?: string;
  ctaVariation?: 'outline-dark' | 'outline' | 'default' | 'basic' | 'opaque';
  ctaNode?: ReactNode;
  hideImage?: boolean;
  image?: keyof typeof images;
  size?: keyof typeof sizes;
  icon?: {
    icon?: keyof typeof Icons;
    props?: Icons.IconProps;
  };
}

export const MessageDisplay: FC<
  MessageDisplayPropTypes & JSX.IntrinsicElements['div']
> = ({
  heading,
  subheading,
  show = true,
  variation = 'default',
  className = '',
  openModal,
  cta,
  ctaVariation = 'default',
  ctaNode,
  hideImage = false,
  children,
  image = null,
  size = 'default',
  icon,
  id,
}) => {
  if (!show) return null;

  let Icon = null;

  if (icon?.icon) Icon = Icons[icon?.icon];

  return (
    <div
      id={id ?? ''}
      className={
        sizes[size].container +
        ' rounded-lg flex items-center border-2 justify-between flex-col md:flex-row ' +
        variations[variation].classNames.join(' ') +
        ' ' +
        (className || '')
      }
    >
      <div className="flex items-center w-full">
        {!hideImage && !Icon && (
          <div className="mr-4 hidden md:block">
            <img
              className="w-16"
              src={image ? images[image] : variations[variation].image}
              alt=""
            />
          </div>
        )}
        {Icon && <Icon {...icon?.props} />}
        <div className={`w-full ${cta && 'mr-0 md:mr-4'}`}>
          {heading && (
            <Text.Heading
              as="h4"
              variant="none"
              className={`${sizes[size].heading} leading-snug mb-1 font-bold`}
            >
              {heading}
            </Text.Heading>
          )}
          {subheading && (
            <p
              dangerouslySetInnerHTML={{ __html: subheading as string }}
              className="fs14 md:fs16"
            ></p>
          )}
          {children}
        </div>
      </div>
      {ctaNode}
      {cta && openModal && (
        <div className="w-full md:w-auto">
          <Button
            className="whitespace-nowrap mt-6 md:mt-0 w-full md:w-auto"
            onClick={() => openModal(true)}
            format={ctaVariation}
          >
            {cta}
          </Button>
        </div>
      )}
    </div>
  );
};
